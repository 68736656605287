<template>
  <div class="card-body">
    <v-data-table
      dense
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allSkladList"
      hide-default-footer
      single-select
      @click:row="rowClick"
      item-key="id"
      class="elevation-1 row-pointer"
      no-data-text="Maʼlumot kiritilmagan"
    >
      <template v-slot:[`item.get_doc_products_total.total`]="{ item }">
        {{
          item.get_doc_products_total &&
          toRoundFloat(item.get_doc_products_total.total)
        }}
      </template>

      <template v-slot:[`item.get_doc_products_total.nds_summa`]="{ item }">
        {{
          item.get_doc_products_total &&
          toRoundFloat(item.get_doc_products_total.nds_summa)
        }}
      </template>

      <template
        v-slot:[`item.get_doc_products_total.total_withnds_summa`]="{ item }"
      >
        {{
          item.get_doc_products_total.total_withnds_summa &&
          toRoundFloat(item.get_doc_products_total.total_withnds_summa)
        }}
      </template>

      <template v-slot:[`item.oper_date`]="{ item }">
        {{ item.oper_date | formatDate }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <action :index="item" @deleteProductsCard="deleteProductsCard"></action>
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import action from './action'
import { toRoundFloat } from '@/utils'
import Swal from 'sweetalert2'
export default {
  components: { action },
  data() {
    return {
      perPage: 10,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1
    }
  },
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.TYPE'),
          value: 'placement_type_name'
        },
        {
          text: this.$t('TABLE_HEADER.DATE'),
          value: 'oper_date'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: 'Kirim Skladi',
          value: 'placement_warehouse_name'
        },
        {
          text: 'Sotuvchi',
          value: 'seller_to_corpcard_cash'
        },
        {
          text: this.$t('TABLE_HEADER.EXPENDITURE'),
          value: 'expenditure_description.name'
        },
        {
          text: this.$t('TABLE_HEADER.AMOUNT'),
          value: 'get_doc_products_total.total'
        },
        {
          text: this.$t('TABLE_HEADER.NDSAMOUNT'),
          value: 'get_doc_products_total.nds_summa'
        },
        {
          text: this.$t('TABLE_HEADER.NDS_AMOUNT'),
          value: 'get_doc_products_total.total_withnds_summa'
        },
        {
          value: 'action',
          text: ''
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.productsList
    },
    allSkladList() {
      var data = []
      data = this.$store.state.requests.productsList.results
      if (data !== undefined) {
        data.forEach((element) => {
          if (
            element.get_doc_products_total.total !== null &&
            element.get_doc_products_total.nds_summa !== null &&
            element.get_doc_products_total.total_withnds_summa !== null
          ) {
            element.get_doc_products_total.total =
              element.get_doc_products_total.total.toLocaleString('es-US')
            element.get_doc_products_total.nds_summa =
              element.get_doc_products_total.nds_summa.toLocaleString('es-US')
            element.get_doc_products_total.total_withnds_summa =
              element.get_doc_products_total.total_withnds_summa.toLocaleString(
                'es-US'
              )
          }
        })
      }
      return data
    }
  },
  methods: {
    toRoundFloat,
    deleteProductsCard(id) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('deleteProductsCard', id)
          this.$store.dispatch('getProductsList3', this.$route.params.id)
          Swal.fire('Ochirildi!', '', 'success')
        }
      })
    },
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/servicecashdetails/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          page: value,
          payload: this.$store.state.requests.filterData.data
        }
        if (
          Object.keys(this.$store.state.requests.filterData.data).length > 0
        ) {
          this.$store.dispatch('corpCardSearch', asd)
        } else {
          this.$store.dispatch('getProductsList3', value)
        }
        if (this.$route.path !== '/servicecard/' + value) {
          this.$router.push('/servicecard/' + value)
        }
      } else {
        this.$store.dispatch('getProductsList3', value)
        if (this.$route.path !== '/servicecard/' + value) {
          this.$router.push('/servicecard/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
